.mission-element{    
    border-style: solid;
    border-color: #0077b8;
    border-radius: 15px;
    padding: 3%;
}
.mission-element:hover{    
    background-color: #0077b8;
    transition-duration: 0.3s;
    color: white;
    transition-duration: 0.3s;
}