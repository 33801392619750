#navbar-top{
    box-shadow: 0px 3px 7.5px rgba(0, 0, 0, 0.1);
}
.nav-link{
    margin-right: 4px;
}

.navbar .current-page {
    color: #0077b8;
}
.navbar .navbar-item-hover:hover{
    background-color: #0077b8;
    color: white;
}


.nav-item{
    text-align: center;
}