.whatwedo-element{
    border-style: solid;
    border-color: #0077b8;
    border-radius: 15px;
}
.whatwedo-element-hover:hover {
    background-color: #0077b8;
    transition-duration: 0.3s;
}
.custom-link {
    color: inherit;
    text-decoration: none;
}
.custom-link:hover{
    color: white;
    transition-duration: 0.3s;
}