#video-homepage{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
#overlay{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
}